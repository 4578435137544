import React, { Fragment } from "react"
import PageLayout from '../../../layouts/page-layout';
import {graphql} from "gatsby";

const Page = ({data}) => {
  return (
    <PageLayout
      data={data} title="Steve Lock Ltd"
      description="Near Ipswich, Suffolk"
    >
      <div className="row">

        <div className="span5">
          <p><strong>Address:</strong></p>
          <address>
            Steve Lock Ltd<br />
            Utopia Manningtree Road<br />
            Ipswich<br />
            Suffolk<br />
            IP9 2SW<br />
          </address>

          <span title="Phone"><strong>Phone:</strong> 01473 328 038</span><br />
		<span title="Website"><strong>Website:</strong><br />
				<a rel="nofollow" href="http://">website</a>
							</span><br />
		<span title="Website"><strong>Twitter:</strong><br />
				</span><br />
        </div>

        <div className="span3">
          <img alt="Recommended site"
               src="/img/badges/recomm2-200.png"/><br />

        </div>
      </div>


    </PageLayout>
  );
};

export default Page;

export const query = graphql`
  query {
    profile: profileYaml {
      ...ProfileFragment
    }
    site {
      siteMetadata {
        title,
        showThemeLogo
      }
    }
  }
`
